@use '@/styles/utils/mixins.scss' as *;

.timelineWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;

  @include tab() {
    display: flex;
    flex-direction: column;
  }

  .leftContent {
    .heading {
      color: $primary;
      font-weight: 500;
    }
  }

  .rightContent {
    width: calc(100% + 3.75rem);
    grid-column: span 2;
    margin-right: -3.75rem;
    display: flex;
    @include tab() {
      width: 100%;
      margin-right: 0;
    }

    .rightContentContainer {
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      width: 100%;
    }
    .cardContentWrapper {
      display: flex;
      align-items: flex-start;
      gap: 5rem;

      .cardYear {
        color: $primary;
        font-weight: 500;
        font-size: 1.5rem;
        white-space: nowrap;
      }

      :global {
        .swiper-slide {
          max-width: 22.75rem;
          display: flex;
          flex-direction: column;
          gap: 0.875rem;
          width: 100%;
        }
        .swiper-wrapper {
          align-items: flex-start;
        }
      }
    }

    .swipeWrapper {
      margin-top: 2.25rem;
      margin-right: 3.75rem;
      margin-left: auto;

      @include tab() {
        margin-right: 1rem;
      }
      .swiperIcon {
        display: flex;
        align-items: center;
        gap: 1.25rem;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
}
